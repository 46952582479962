import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/var/lib/jenkins/workspace/ancare-business-prod/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/UserLayout'),
          LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/login/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../login'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../login').default,
        exact: true,
      },
    ],
  },
  {
    path: '/ipad',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BlankLayout'),
          LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/ipad/personal',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/iPad/personal/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../iPad/personal'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../iPad/personal').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BasicLayout'),
          LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    name: '首页',
    authority: ['admin', 'assiant', 'guidance'],
    routes: [
      {
        path: '/',
        redirect: '/dashboard',
        exact: true,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        text: '工作台',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/dashboard/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../dashboard'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../dashboard').default,
        exact: true,
      },
      {
        path: '/dashboardold',
        name: 'dashboard',
        text: '工作台(老)',
        icon: 'dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/dashboard/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../dashboard/indexold'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../dashboard/indexold').default,
        exact: true,
      },
      {
        path: '/personal',
        name: 'personal',
        text: '客户管理',
        icon: 'user',
        authority: ['admin', 'assiant', 'guidance'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/personal/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../personal'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../personal').default,
        exact: true,
      },
      {
        path: '/visitFollow',
        name: 'visitFollow',
        text: '回访跟进',
        icon: 'user',
        authority: ['admin', 'assiant', 'guidance'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/visitFollow/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../visitFollow'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../visitFollow').default,
        exact: true,
      },
      {
        path: '/reserve',
        name: 'reserve',
        text: '预约管理',
        icon: 'reconciliation',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/reserve/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../reserve'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../reserve').default,
        exact: true,
      },
      {
        path: '/summary',
        name: 'summary',
        text: '预约管理',
        icon: 'reconciliation',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/summary/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../summary'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../summary').default,
        exact: true,
      },
      {
        path: '/refund',
        name: 'refund',
        text: '退款管理',
        icon: 'profile',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/refund/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../refund'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../refund').default,
        exact: true,
      },
      {
        path: '/purposeclinic',
        name: 'purposeclinic',
        text: '目标管理',
        icon: 'line-chart',
        routes: [
          {
            path: '/purposeclinic/setting',
            name: 'purposeclinic-setting',
            text: '目标设置',
            authority: ['admin'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/purpose/setting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../purpose/setting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../purpose/setting').default,
            exact: true,
          },
          {
            path: '/purposeclinic/info',
            name: 'purposeclinic-info',
            text: '目标进度',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/purpose/info/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../purpose/info'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../purpose/info').default,
            exact: true,
          },
        ],
      },
      {
        path: '/order',
        name: 'order',
        text: '订单管理',
        icon: 'profile',
        routes: [
          {
            path: '/order/list',
            name: 'order-list',
            text: '订单列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/order/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../order'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../order').default,
            exact: true,
          },
          {
            path: '/order/collection/list',
            name: 'collection-list',
            text: '收款列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/collection/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../collection'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../collection').default,
            exact: true,
          },
          {
            path: '/order/orderdel',
            name: 'order-del',
            text: '删除订单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/orderdel/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../orderdel'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../orderdel').default,
            exact: true,
          },
        ],
      },
      {
        path: '/test',
        name: 'test',
        text: 'test',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../test'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../test').default,
        exact: true,
      },
      {
        path: '/commission',
        name: 'commission',
        text: '佣金管理',
        icon: 'profile',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/commission/list',
            name: 'commission-list',
            text: '升单激励',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/commission/list/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../commission/list'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../commission/list').default,
            exact: true,
          },
        ],
      },
      {
        path: '/statistic',
        name: 'statistic',
        text: '数据统计',
        icon: 'profile',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/statistic/operation',
            name: 'statistic-operation',
            text: '运营分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/operation/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/operation'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/operation').default,
            exact: true,
          },
          {
            path: '/statistic/report',
            name: 'statistic-report',
            text: '周月报表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/report/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/report'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/report').default,
            exact: true,
          },
          {
            path: '/statistic/statisticChart',
            name: 'statistic-statisticChart',
            text: '报表统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/statisticChart/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/statisticChart'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/statisticChart').default,
            exact: true,
          },
          {
            path: '/statistic/original',
            name: 'statistic-original',
            text: '原始数据统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/original/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/original'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/original').default,
            exact: true,
          },
          {
            path: '/statistic/monthReport',
            name: 'statistic-monthReport',
            text: '月报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/monthReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/monthReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/monthReport').default,
            exact: true,
          },
          {
            path: '/statistic/weekReport',
            name: 'statistic-weekReport',
            text: '周报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/weekReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/weekReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/weekReport').default,
            exact: true,
          },
          {
            path: '/statistic/dailyReport',
            name: 'daily-Report',
            text: '日报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistic/dailyReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/dailyReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/dailyReport').default,
            exact: true,
          },
        ],
      },
      {
        path: '/statistics',
        name: 'statistics',
        text: '数据统计(在线医助)',
        icon: 'profile',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/statistics/operations',
            name: 'statistics-operations',
            text: '运营分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistics/operations/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistics/operations'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistics/operations').default,
            exact: true,
          },
          {
            path: '/statistics/reports',
            name: 'statistics-reports',
            text: '周月报表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistics/reports/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistics/reports'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistics/reports').default,
            exact: true,
          },
          {
            path: '/statistics/statisticCharts',
            name: 'statistics-statisticCharts',
            text: '报表统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/statistics/statisticCharts/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistics/statisticCharts'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistics/statisticCharts').default,
            exact: true,
          },
        ],
      },
      {
        path: '/manager',
        name: 'manager',
        text: '门店管理',
        icon: 'user',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/manager/info',
            name: 'manager-info',
            text: '基本信息',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/info/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/info'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/info').default,
            exact: true,
          },
          {
            path: '/manager/organization',
            name: 'manager-organization',
            text: '组织机构',
            authority: ['admin', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/organization/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/organization'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/organization').default,
            exact: true,
          },
          {
            path: '/manager/role',
            name: 'manager-role',
            text: '门店角色',
            authority: ['admin', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/role/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/role'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/role').default,
            exact: true,
          },
          {
            path: '/manager/channel',
            name: 'manager-channel',
            text: '渠道管理',
            authority: ['admin', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/channel/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/channel'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/channel').default,
            exact: true,
          },
          {
            path: '/manager/channelType',
            name: 'manager-channelType',
            text: '渠道分类管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/channelType/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/channelType'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/channelType').default,
            exact: true,
          },
          {
            path: '/manager/commodity/list',
            name: 'manager-commodity-list',
            text: '商品列表',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/commodity/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../commodity'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../commodity').default,
            exact: true,
          },
          {
            path: '/manager/combo/list',
            name: 'manager-combo-list',
            text: '套餐列表',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/combo/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/combo'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/combo').default,
            exact: true,
          },
          {
            path: '/manager/commodity/add',
            name: 'add',
            text: '新建商品',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/commodity/add/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/commodity/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../commodity/add'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../commodity/add').default,
            exact: true,
          },
          {
            path: '/manager/combo/add',
            name: 'manager-combo-add',
            text: '添加套餐',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/combo/add/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/combo/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/combo/add'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/combo/add').default,
            exact: true,
          },
          {
            path: '/manager/combo/edit',
            name: 'manager-combo-edit',
            text: '编辑套餐',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/combo/edit/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/combo/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/combo/edit'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/combo/edit').default,
            exact: true,
          },
          {
            path: '/manager/promotion/rules',
            name: 'manager-promotion-rules',
            text: '升单规则',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/newPromotionRules/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/newPromotionRules'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/newPromotionRules').default,
            exact: true,
          },
          {
            path: '/manager/promotion/product',
            name: 'manager-promotion-product',
            text: '升单商品',
            authority: ['admin', 'assiant', 'guidance'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/manager/newPromotionProduct/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../manager/newPromotionProduct'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../manager/newPromotionProduct').default,
            exact: true,
          },
        ],
      },
      {
        path: '/task',
        name: 'task',
        text: '医嘱执行',
        icon: 'project',
        routes: [
          {
            path: '/task',
            name: 'task-list',
            text: '服务记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/list/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../task/list'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../task/list').default,
            exact: true,
          },
          {
            path: '/task/:id',
            name: 'task-edit',
            text: '医嘱详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../task/edit'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../task/edit').default,
            routes: [
              {
                path: '/task/:id/stage-report',
                name: 'stage-report',
                text: '阶段性评估报告',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/stage-report/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/stage-report'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/stage-report').default,
                exact: true,
              },
              {
                path: '/task/:id/service-template1',
                name: 'service-template1',
                text: '服务模板一',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/service-template1/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/service-template1'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/service-template1').default,
                exact: true,
              },
              {
                path: '/task/:id/service-template2',
                name: 'service-template2',
                text: '服务模板二',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/service-template2/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/service-template2'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/service-template2').default,
                exact: true,
              },
              {
                path: '/task/:id/service-template3',
                name: 'service-template3',
                text: '服务模板三',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/service-template3/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/service-template3'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/service-template3').default,
                exact: true,
              },
              {
                path: '/task/:id/bsp-visit',
                name: 'bsp-visit',
                text: 'BSP首诊记录',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/bsp-visit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/bsp-visit'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/bsp-visit').default,
                exact: true,
              },
              {
                path: '/task/:id/scale',
                name: 'scale',
                text: '量表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/scale/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/scale'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/scale').default,
                exact: true,
              },
              {
                path: '/task/:id/evaluation',
                name: 'evaluation',
                text: '评测报告',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/evaluation/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/evaluation'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/evaluation').default,
                exact: true,
              },
              {
                path: '/task/:id/evaluation-new',
                name: 'evaluation-new',
                text: 'BPS首咨报告',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/evaluation-new/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/evaluation-new'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/evaluation-new').default,
                exact: true,
              },
              {
                path: '/task/:id/specialist-test',
                name: 'specialist-test',
                text: '专科测评',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/specialist-test/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/specialist-test'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/specialist-test').default,
                exact: true,
              },
              {
                path: '/task/:id/demand-analysis',
                name: 'demand-analysis',
                text: '需求分析',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/demand-analysis/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/demand-analysis'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/demand-analysis').default,
                exact: true,
              },
              {
                path: '/task/:id/painting',
                name: 'painting',
                text: '绘画影射分析',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/painting/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/painting'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/painting').default,
                exact: true,
              },
              {
                path: '/task/:id/healing',
                name: 'healing',
                text: '艺术疗愈记录',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/healing/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/healing'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/healing').default,
                exact: true,
              },
              {
                path: '/task/:id/subsequent',
                name: 'subsequent',
                text: '复诊记录表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/subsequent/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/subsequent'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/subsequent').default,
                exact: true,
              },
              {
                path: '/task/:id/service-subsequent-complex',
                name: 'service-subsequent-complex',
                text: '复诊记录表详',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/service-subsequent-complex/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import('../task/edit/service-subsequent-complex'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/service-subsequent-complex').default,
                exact: true,
              },
              {
                path: '/task/:id/subsequent-brief',
                name: 'subsequent-brief',
                text: '复诊记录表简',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/subsequent-brief/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/subsequent-brief'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/subsequent-brief').default,
                exact: true,
              },
              {
                path: '/task/:id/dpp-treatment',
                name: 'dpp-treatment',
                text: 'DPP循程治疗方案',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/dpp-treatment/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/dpp-treatment'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/dpp-treatment').default,
                exact: true,
              },
              {
                path: '/task/:id/home-psychology',
                name: 'home-psychology',
                text: '居家心理健康',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/home-psychology/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                        import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/task/edit/model.js').then(
                          m => {
                            return { namespace: 'model', ...m.default };
                          },
                        ),
                      ],
                      component: () => import('../task/edit/home-psychology'),
                      LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../task/edit/home-psychology').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/assignment',
        name: 'assignment',
        text: '所务管理',
        icon: 'project',
        routes: [
          {
            path: '/assignment/people-scheduling',
            name: 'people-scheduling',
            text: '人员排班',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/assignment/people-scheduling/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../assignment/people-scheduling'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../assignment/people-scheduling').default,
            exact: true,
          },
          {
            path: '/assignment/people-scheduling-assistant',
            name: 'people-scheduling-assistant',
            text: '人员排班(集团医助)',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/assignment/people-scheduling-assistant/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../assignment/people-scheduling-assistant'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../assignment/people-scheduling-assistant').default,
            exact: true,
          },
          {
            path: '/assignment/room-setting',
            name: 'room-setting',
            text: '房间设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/assignment/room-setting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../assignment/room-setting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../assignment/room-setting').default,
            exact: true,
          },
          {
            path: '/assignment/schedule-people',
            name: 'schedule-people',
            text: '员工排班',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/assignment/schedule-people/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../assignment/schedule-people'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../assignment/schedule-people').default,
            exact: true,
          },
        ],
      },
      {
        path: '/summary',
        name: 'summary',
        text: '汇总统计',
        icon: 'project',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/summary/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../summary'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../summary').default,
        exact: true,
      },
      {
        path: '/summaryDetail',
        name: 'summaryDetail',
        text: '日视图',
        icon: 'project',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/summary/detail/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/summary/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../summary/detail'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../summary/detail').default,
        exact: true,
      },
      {
        path: '/qualityTesting',
        name: 'qualityTesting',
        text: '质检管理',
        icon: 'project',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/qualityTesting/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../qualityTesting'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../qualityTesting').default,
        exact: true,
      },
      {
        path: '/scalesManager',
        name: 'scalesManager',
        text: '量表管理',
        icon: 'profile',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-business-prod/src/pages/scalesManager/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../scalesManager'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-business-prod/src/components/PageLoading/index')
                .default,
            })
          : require('../scalesManager').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
