// 接口代理前缀
export const BASE_API = '/apis'
export const MOCK_API = '/mock'
export const BASE_WENJUAN = '/wenquan'
export const WENJUAN_URL = 'http://116.62.137.237:9528'
// token key
export const TOKEN_KEY = 'fulcrum-ancare-token'

export const ROLE_KEY = 'fulcrum-ancare-role'
export const ROLE_CODE = 'fulcrum-ancare-role-code'
export const CLINIC_KEY = 'fulcrum-ancare-clinic-id'
export const USER_ID = 'fulcrum-ancare-user-id'
export const CLINIC_LOGO = 'fulcrum-ancare-clinic-logo'
export const CLINIC_NAME = 'fulcrum-ancare-clinic-name'
export const APP_CODE = 'applicationId'
export const DEFAULT_ID = 'defaultId'
export const FIRST_ROUTE = 'first-route'
export const IS_SIMPLE_PWD = 'isSimplePwd'

export const ROLE_DATAPERMISSION_KEY = 'fulcrum-ancare-role-data-permission'

// 婚姻状况枚举
export const MARITAL_STATUS_ENUM = [{
  label: '未婚',
  value: 'WEIHUN'
}, {
  label: '已婚',
  value: 'YIHUN'
}, {
  label: '离异',
  value: 'LIYI'
}, {
  label: '再婚',
  value: 'ZAIHUN'
}]

// 学历
export const EDUCATION_ENUM = [{
  label: '初中及以下',
  value: 'junior'
}, {
  label: '高中',
  value: 'senior'
}, {
  label: '大专',
  value: 'junior_college'
}, {
  label: '本科',
  value: 'college'
}, {
  label: '硕士',
  value: 'master'
}, {
  label: '博士',
  value: 'doctor'
}]

// 客户级别
export const CLASSIFICATION_NUM = [{
  label: 'A：1个月内可能到门店',
  value: 'A'
}, {
  label: 'B：3个月内可能到门店',
  value: 'B'
}, {
  label: 'C：大于3个月内可能到门店',
  value: 'C'
}, {
  label: 'D：没有意向',
  value: 'D'
}]

// 职业
export const JOB_ENUM = [{
  label: '计算机 / 通信 / 互联网',
  value: '计算机 / 通信 / 互联网'
}, {
  label: '生产 / 制造 / 工艺',
  value: '生产 / 制造 / 工艺'
}, {
  label: '医疗 / 护理 / 制药',
  value: '医疗 / 护理 / 制药'
}, {
  label: '金融 / 银行 / 投资 / 保险',
  value: '金融 / 银行 / 投资 / 保险'
}, {
  label: '商业 / 服务业 / 个体经营',
  value: '商业 / 服务业 / 个体经营'
}, {
  label: '文化 / 广告 / 传媒',
  value: '文化 / 广告 / 传媒'
}, {
  label: '娱乐 / 艺术 / 表演',
  value: '娱乐 / 艺术 / 表演'
}, {
  label: '律师 / 法务',
  value: '律师 / 法务'
}, {
  label: '教育 / 培训',
  value: '教育 / 培训'
}, {
  label: '公务员 / 行政 / 事业单位',
  value: '公务员 / 行政 / 事业单位'
}, {
  label: '模特',
  value: '模特'
}, {
  label: '空姐',
  value: '空姐'
}, {
  label: '学生',
  value: '学生'
}, {
  label: '其他',
  value: '其他'
}]

// 性别
export const GENDER_ENUM = [{
  label: '男',
  value: 1
}, {
  label: '女',
  value: 2
}]

// 预约状态
export const RESERVE_STATUS_ENUM = [{
  label: '待确认',
  value: 'to_be_confirm'
}, {
  label: '待预约',
  value: 'to_be_reservation'
}, {
  label: '预约成功',
  value: 'success'
}, {
  label: '到店',
  value: 'arrival'
}, {
  label: '取消',
  value: 'cancel'
}]
// 预约状态
export const ORDER_STATUS_ENUM = [{
  label: '待执行',
  value: 'to_be_order'
}, {
  label: '执行中',
  value: 'in_order'
}, {
  label: '已完成',
  value: 'success'
}]
export const CUSTOM_INFO = [{
  label: '姓名',
  value: 'customerName'
}, {
  label: '性别',
  value: 'gender'
}, {
  label: '手机号码',
  value: 'phone'
}, {
  label: '微信号',
  value: 'weixinId'
}, {
  label: '身高',
  value: 'height'
}, {
  label: '体重',
  value: 'weight'
}, {
  label: '婚姻状况',
  value: 'maritalStatus'
}, {
  label: '出生日期',
  value: 'birthday'
}, {
  label: '学历',
  value: 'education'
}, {
  label: '现居城市',
  value: 'familyAddress'
}, {
  label: '职业',
  value: 'occupation'
}, {
  label: '客户级别',
  value: 'classification'
}, {
  label: '一级渠道',
  value: 'firstChannelId'
}, {
  label: '二级渠道',
  value: 'secondChannelId'
}, {
  label: '可预约时间',
  value: 'appointmentHabit'
}, {
  label: '备注',
  value: 'comment'
}, {
  label: '父亲姓名',
  value: 'fatherName'
}, {
  label: '父亲年龄',
  value: 'fatherAge'
}, {
  label: '父亲职业',
  value: 'fatherOccupation'
}, {
  label: '父亲电话',
  value: 'fatherTelephone'
}, {
  label: '父亲联系人',
  value: 'fatherEmergency'
}, {
  label: '母亲姓名',
  value: 'motherName'
}, {
  label: '母亲年龄',
  value: 'motherAge'
}, {
  label: '母亲职业',
  value: 'motherOccupation'
}, {
  label: '母亲电话',
  value: 'motherTelephone'
}, {
  label: '母亲联系人',
  value: 'motherEmergency'
}, {
  label: '问题类型',
  value: 'questionType'
}, {
  label: '其他问题',
  value: 'otherQuestion'
}, {
  label: '核心诉求描述',
  value: 'coreRequirement'
}
]

// 产品服务类型
// export const PRODUCT_TYPE= [{
//   label: '首诊',
//   value: 'first_visit'
// }, {
//   label: '复诊',
//   value: 'subsequent_visit'
// }]


// 产品服务类型
export const PRODUCT_TYPE = [{
  label: '诊疗类服务',
  value: 'diagnostic_and_treatment_service'
}, {
  label: '非诊疗类服务',
  value: 'non_diagnostic_and_treatment_service'
}]

// 诊疗状态Diagnosis
export const DIAGNOSIS_TYPE = [{
  label: '首诊',
  value: 'first_visit_customer'
}, {
  label: '复诊',
  value: 'subsequent_visit_customer'
}, {
  label: '无',
  value: 'normal_customer'
}
  // ,{
  //   label: '普通',
  //   value: 'normal_customer'
  // }
]

// 线上线下
export const ONLINE_TYPE = [{
  label: '线上',
  value: 'online'
}, {
  label: '线下',
  value: 'offline'
}]

// 请求成功状态码
export const HTTP_SUCCESS_CODE = 0

// 字典角色类型
export const ROLE_TYPE = [{
  label: '集团',
  value: 'PLATFORM',
}, {
  label: '门店',
  value: 'CLINIC'
}]

// 待确认订单状态
export const ORDER_STATUS_TO_CONFIRM = 'to_confirm'

// 待支付订单状态
export const ORDER_STATUS_TO_PAY = 'to_pay'

// 已取消订单状态
export const ORDER_STATUS_CANCEL = 'cancel'

// 部分支付订单状态
export const ORDER_STATUS_PART_PAY = 'part_pay'

// 已支付订单状态
export const ORDER_STATUS_PAID = 'paid'

// 部分退款订单状态
export const ORDER_STATUS_PART_REFUND = 'part_refund'

// 退款中订单状态
export const ORDER_STATUS_REFUNDING = 'refunding'

// 已退款订单状态
export const ORDER_STATUS_REFUNDED = 'refunded'

// 已完成订单状态
export const ORDER_STATUS_SUCCESS = 'success'

// 订单状态map
export const ORDER_STATUS_MAP = {
  [ORDER_STATUS_TO_CONFIRM]: '待确认',
  [ORDER_STATUS_TO_PAY]: '待支付',
  [ORDER_STATUS_PAID]: '已支付',
  // [ORDER_STATUS_CANCEL]: '已取消',
  [ORDER_STATUS_PART_PAY]: '部分支付',
  // [ORDER_STATUS_PART_REFUND]: '部分退款',
  // [ORDER_STATUS_REFUNDING]: '退款中',
  // [ORDER_STATUS_REFUNDED]: '已退款',
  [ORDER_STATUS_SUCCESS]: '已完成',
}

// 待预约-执行状态
export const ORDER_EXECUTION_STATUS_TO_RESERVATION = 'to_reservation'

// 执行中-执行状态
export const ORDER_EXECUTION_STATUS_EXECUTION = 'execution'

// 部分完成-执行状态
export const ORDER_EXECUTION_STATUS_PART_SUCCESS = 'part_success'

// 已完成-执行状态
export const ORDER_EXECUTION_STATUS_SUCCESS = 'success'

// 执行状态map
export const ORDER_EXECUTION_STATUS_MAP = {
  [ORDER_EXECUTION_STATUS_TO_RESERVATION]: '待预约',
  [ORDER_EXECUTION_STATUS_EXECUTION]: '执行中',
  [ORDER_EXECUTION_STATUS_PART_SUCCESS]: '部分完成',
  [ORDER_EXECUTION_STATUS_SUCCESS]: '已完成'
}

// 定金
export const PAY_METHOD_DEPOSIT = 'deposit'

// 周期性付款
export const PAY_METHOD_PERIODIC_SETTLEMENT = 'periodic_settlement'

// 付费习惯
export const PAY_METHOD_MAP = {
  imprest: '预付款',
  cash_in_full: '现金全款',
  [PAY_METHOD_DEPOSIT]: '定金',
  [PAY_METHOD_PERIODIC_SETTLEMENT]: '周期性结算'
}

export const PAY_METHODS = Object.keys(PAY_METHOD_MAP).map(key => ({ name: PAY_METHOD_MAP[key], value: key }))

// 支付方式
export const PAYMENT_METHOD_MAP = {
  cash_pay: '现金支付',
  // online_pay: '在线支付',
  // scan_pay: '线下扫码',
  pos_pay: '线下POS机',
  // transfer_in_wechat: '微信转账',
  // wechat_pay: '微信支付',
  // alipay: '支付宝支付',
  meituan_coupon: '大众美团验券',
  koubei_coupon: '口碑验券',
  tmall_coupon: '天猫验券',
  wechat_mini_program: '微信小程序',
  medicare: '医保',
  // bank_transfer: '银行转账',
  pinduoduo_coupon: '拼多多验券',
  other_coupon: '其他验券',
  remote_payment: '远程支付',
  transfer_in_wechat: '微信转账',
  alipay_transfer: '支付宝转账',
  to_company_pay: '对公支付',
  to_code_pay: '扫码支付',
}


// 线上线下 支付方式 的显示
export const PAYMENT_METHOD_SHOW = {
  cash_pay: '现金支付',
  pos_pay: '线下POS机',
  meituan_coupon: '大众美团验券',
  koubei_coupon: '口碑验券',
  tmall_coupon: '天猫验券',
  wechat_mini_program: '微信小程序',
  medicare: '医保',
  pinduoduo_coupon: '拼多多验券',
  other_coupon: '其他验券',
  remote_payment: '远程支付',
  transfer_in_wechat: '微信转账',
  alipay_transfer: '支付宝转账',
  to_company_pay: '对公支付',
  to_code_pay: '扫码支付',
  //线上支付方式
  WXZF: '微信支付',
  ZFBZF: '支付宝支付',
  UPSMPAY: '银联扫码',
  DCPAY: '数字货币'
}

export const PAYMENT_METHODS = Object.keys(PAYMENT_METHOD_MAP).map(key => ({ name: PAYMENT_METHOD_MAP[key], value: key }))
export const PAYMENT_METHODS_SHOW = Object.keys(PAYMENT_METHOD_SHOW).map(key => ({ name: PAYMENT_METHOD_SHOW[key], value: key }))

//支付方式 新增收款
export const PAYMENT_TYPE_IN_COLLECTION = [{
  label: '线上支付',
  value: 'online_payment'
}, {
  label: '线下补录',
  value: 'offline_supplement'
},]

export const PAYMENT_TYPE_IN_COLLECTION_OBJ = {
  online_payment: '线上支付',
  offline_supplement: '线下补录'
}

//线上退款 or  线下退款
export const REFUND_TYPE = [{
  label: '线上退款',
  value: 'online_refund'
}, {
  label: '线下退款',
  value: 'offline_refund'
},]

export const REFUND_TYPE_OBJ = {
  online_refund: '线上退款',
  offline_refund: '线下退款'
}

//支付状态
export const PAYMENT_STATUS = {
  to_pay: "待支付",
  paying: "支付中",
  success: "支付成功",
  fail: "支付失败"

}

export const PAYMENT_STATUS_ARRAY = Object.keys(PAYMENT_STATUS).map((key) => { return { label: PAYMENT_STATUS[key], value: key } })

// 退款方式
export const REFUND_METHOD_MAP = {
  cash_pay: '现金支付',
  online_pay: '在线支付',
  transfer_in_wechat: '微信转账',
  wechat_pay: '微信支付',
  alipay: '支付宝支付',
  bank_transfer: '银行转账',
  remote_payment: '远程支付',
  // wechat_transfer:'微信转账',
  alipay_transfer: '支付宝转账',
  to_company_pay: '对公支付',
  to_code_pay: '扫码支付',
}

// 线上退款时候的显示   退款方式
export const REFUND_METHOD_SHOW = {
  cash_pay: '现金支付',
  online_pay: '在线支付',
  transfer_in_wechat: '微信转账',
  wechat_pay: '微信支付',
  alipay: '支付宝支付',
  bank_transfer: '银行转账',
  remote_payment: '远程支付',
  // wechat_transfer:'微信转账',
  alipay_transfer: '支付宝转账',
  to_company_pay: '对公支付',
  to_code_pay: '扫码支付',
  WXZF: '微信支付',
  ZFBZF: '支付宝支付',
  UPSMPAY: '银联二维码',
  DCPAY: '数字货币',
}

export const REFUND_METHODS = Object.keys(REFUND_METHOD_MAP).map(key => ({ name: REFUND_METHOD_MAP[key], value: key }))
//线上退款的时候的显示
export const REFUND_METHODS_SHOW = Object.keys(REFUND_METHOD_SHOW).map(key => ({ name: REFUND_METHOD_SHOW[key], value: key }))

export const PERIOD_CUSTOM = 'custom'

// 周期性结算方式
export const PERIOD_MAP = {
  'week': '按周',
  'month': '按月',
  [PERIOD_CUSTOM]: '自定义'
}

export const PERIODS = Object.keys(PERIOD_MAP).map(key => ({ name: PERIOD_MAP[key], value: key }))

export const BPS_SELECTION_CONFIG = {
  SYMPTOM: {
    SLEEP: [{  // 睡眠状况 近期情况
      label: '较好',
      value: '较好',
    }, {
      label: '尚可',
      value: '尚可',
    }, {
      label: '变差',
      value: '变差',
    }],
    DIET: [{  // 饮食状况 近期情况
      label: '较好',
      value: '较好',
    }, {
      label: '尚可',
      value: '尚可',
    }, {
      label: '暴饮暴食',
      value: '暴饮暴食',
    }, {
      label: '变差',
      value: '变差',
    }],
    CARESELF: [{  // 生活自理 近期情况
      label: '较好',
      value: '较好',
    }, {
      label: '尚可',
      value: '尚可',
    }, {
      label: '变差',
      value: '变差',
    }],
    RISK: [{  // 风险评估 近期情况
      label: '消极厌世',
      value: '消极厌世',
    }, {
      label: '自伤',
      value: '自伤',
    }, {
      label: '自杀',
      value: '自杀',
    }, {
      label: '伤人',
      value: '伤人',
    }, {
      label: '冲动行为',
      value: '冲动行为',
    }],
    ATTITUDE: [{    // 对疾病认识态度
      label: '缺乏认识',
      value: '缺乏认识',
    }, {
      label: '部分认识',
      value: '部分认识',
    }, {
      label: '较认识',
      value: '较认识',
    }, {
      label: '完全认识',
      value: '完全认识',
    }],
    BODY_MEDICAL: [{  // 躯体疾病史
      label: '发热',
      value: '发热',
    }, {
      label: '抽搐',
      value: '抽搐',
    }, {
      label: '感染',
      value: '感染',
    }, {
      label: '中毒',
      value: '中毒',
    }, {
      label: '中枢神经系统疾病如脑炎',
      value: '中枢神经系统疾病如脑炎',
    }, {
      label: '脑外伤',
      value: '脑外伤',
    }],
    MENTAL_MEDICAL: [{  // 精神疾病史
      label: '酗酒',
      value: '酗酒',
    }, {
      label: '吸毒',
      value: '吸毒',
    }, {
      label: '性病',
      value: '性病',
    }, {
      label: '自杀',
      value: '自杀'
    }],
    PARENT_MEDICAL: [{  // 遗传精神病史
      label: '精神障碍',
      value: '精神障碍',
    }, {
      label: '人格障碍',
      value: '人格障碍',
    }, {
      label: '癫痫',
      value: '癫痫',
    }, {
      label: '精神发育迟滞',
      value: '精神发育迟滞',
    }, {
      label: '自杀',
      value: '自杀',
    }, {
      label: '近亲婚配',
      value: '近亲婚配',
    }],
    MENSTRUAL: [{  // 月经史
      label: '正常',
      value: '正常',
    }, {
      label: '量少',
      value: '量少',
    }, {
      label: '不规则',
      value: '不规则',
    }, {
      label: '已绝经',
      value: '已绝经',
    }]
  },
  LIFE: {
    FAMILY: [{   //  家族结构
      label: '核心型',
      value: '核心型',
    }, {
      label: '中间型',
      value: '中间型',
    }, {
      label: '大家庭型',
      value: '大家庭型',
    }],
    MEMBER: [{   //  与家人是否同住
      label: '同住',
      value: '同住',
    }, {
      label: '分开住',
      value: '分开住',
    }],
    RELATION: [{   //  与家人相处关系
      label: '和睦',
      value: '和睦',
    }, {
      label: '一般',
      value: '一般',
    }, {
      label: '不和',
      value: '不和',
    }],
    SPOUSE: [{   //  配偶
      label: '离异',
      value: '离异',
    }, {
      label: '分居',
      value: '分居',
    }, {
      label: '亡故',
      value: '亡故',
    }],
  },
  SOCIETY: {
    RECENT: [{   //  社会功能
      label: '较好',
      value: '较好',
    }, {
      label: '尚可',
      value: '尚可',
    }, {
      label: '下降',
      value: '下降',
    }],
    RELATIONSHIP: [{   // 人际关系
      label: '很好',
      value: '很好',
    }, {
      label: '较好',
      value: '较好',
    }, {
      label: '一般',
      value: '一般',
    }, {
      label: '较差',
      value: '较差',
    }, {
      label: '很差',
      value: '很差',
    }],
  },
  PERSONAL: {
    SUBJECTIVE: [{   //  主观评估判断
      label: '内向',
      value: '内向',
    }, {
      label: '外向',
      value: '外向',
    }, {
      label: '好强',
      value: '好强',
    }, {
      label: '固执',
      value: '固执',
    }, {
      label: '畏怯',
      value: '畏怯',
    }, {
      label: '退缩',
      value: '退缩',
    }, {
      label: '敏感',
      value: '敏感',
    }, {
      label: '感情用事',
      value: '感情用事',
    }, {
      label: '刚愎自用',
      value: '刚愎自用',
    }, {
      label: '激进',
      value: '激进',
    }, {
      label: '依赖',
      value: '依赖',
    }, {
      label: '理智',
      value: '理智',
    }, {
      label: '随和',
      value: '随和',
    }, {
      label: '自律',
      value: '自律',
    }, {
      label: '严谨',
      value: '严谨',
    }],
    MOOD: [{      //  情绪特点
      label: '情绪稳定',
      value: '情绪稳定',
    }, {
      label: '心平气和',
      value: '心平气和',
    }, {
      label: '情绪易波动',
      value: '情绪易波动',
    }, {
      label: '紧张',
      value: '紧张',
    }, {
      label: '焦虑',
      value: '焦虑',
    }, {
      label: '抑郁',
      value: '抑郁',
    }, {
      label: '烦恼多',
      value: '烦恼多',
    }],
    BIRTH_MODE: [{    //  生产方式
      label: '足月顺产',
      value: '足月顺产',
    }, {
      label: '难产',
      value: '难产',
    }, {
      label: '钳产',
      value: '钳产',
    }, {
      label: '胎吸',
      value: '胎吸',
    }, {
      label: '剖宫产',
      value: '剖宫产',
    }, {
      label: '早产',
      value: '早产',
    }],
    GROWTH: [{    //  体格发育
      label: '正常',
      value: '正常',
    }, {
      label: '稍差',
      value: '稍差',
    }, {
      label: '不良',
      value: '不良',
    }],
    SENSE: [{    //  感统失调
      label: '前庭平衡功能失常',
      value: '前庭平衡功能失常',
    }, {
      label: '视觉感不良',
      value: '视觉感不良',
    }, {
      label: '触觉过分敏感',
      value: '触觉过分敏感',
    }, {
      label: '听觉感不良',
      value: '听觉感不良',
    }, {
      label: '本体感失调',
      value: '本体感失调',
    }, {
      label: '动作协调不良',
      value: '动作协调不良',
    }],
    INTEREST: [{    //  兴趣爱好
      label: '运动',
      value: '运动',
    }, {
      label: '文学',
      value: '文学',
    }, {
      label: '艺术',
      value: '艺术',
    }, {
      label: '休闲娱乐',
      value: '休闲娱乐',
    }, {
      label: '手工',
      value: '手工',
    }],
    STUDY: [{    //  学习成绩
      label: '很好',
      value: '很好',
    }, {
      label: '较好',
      value: '较好',
    }, {
      label: '一般',
      value: '一般',
    }, {
      label: '较差',
      value: '较差',
    }, {
      label: '很差',
      value: '很差',
    }],
    RELATION: [{    //  同学、朋友、师生 关系
      label: '很好',
      value: '很好',
    }, {
      label: '较好',
      value: '较好',
    }, {
      label: '一般',
      value: '一般',
    }, {
      label: '较差',
      value: '较差',
    }, {
      label: '很差',
      value: '很差',
    }],
    HOBBY: [{    //  不良嗜好
      label: '抽烟',
      value: '抽烟',
    }, {
      label: '酗酒',
      value: '酗酒',
    }, {
      label: '赌博',
      value: '赌博',
    }, {
      label: '物质滥用',
      value: '物质滥用',
    }, {
      label: '性瘾',
      value: '性瘾',
    }],
  },
  FAMILY: {
    PARENT: [{
      label: '在世',
      value: '在世',
    }, {
      label: '亡故',
      value: '亡故',
    }],
    EDUCATION: [{    //  父母相处关系和教养方式
      label: '权威型',
      value: '权威型',
    }, {
      label: '专制型',
      value: '专制型',
    }, {
      label: '放纵型（溺爱型）',
      value: '放纵型（溺爱型）',
    }, {
      label: '忽视型',
      value: '忽视型',
    }],
  }
}

export const TASK_STATUS_TO_EXECUTE = 'to_execute'
export const TASK_STATUS_RUNNING = 'running'
export const TASK_STATUS_FINISHED = 'finished'
export const TASK_STATUS_REFUND = 'refund'
export const TASK_STATUS_UNSTART = 'unstart'
export const TASK_STATUS_CANCEL = 'cancel'

// 任务状态refund
export const TASK_STATUS_MAP = {
  [TASK_STATUS_TO_EXECUTE]: '待执行',
  [TASK_STATUS_RUNNING]: '执行中',
  [TASK_STATUS_FINISHED]: '已完成',
  [TASK_STATUS_REFUND]: '已退款',
  [TASK_STATUS_UNSTART]: '未开始',
  [TASK_STATUS_CANCEL]: '取消'
}

export const TASK_STATUSES = Object.keys(TASK_STATUS_MAP).map(key => ({ name: TASK_STATUS_MAP[key], value: key }))

export const TASK_ITEM_STATUS_FINISHED = 'finished'

export const TASK_ITEM_STATUS_MAP = {
  unfinished: '未完成',
  [TASK_ITEM_STATUS_FINISHED]: '已完成'
}

export const SERVICE_TYPE_SCALE = 'scale'
export const SERVICE_TYPE_REPORT = 'report'
export const SERVICE_TYPE_NEW_REPORT = 'new_bps_report' // 2023-09
export const SERVICE_TYPE_FORM = 'form'
export const SERVICE_TYPE_DETECTION = 'evaluation_and_testing'
export const SERVICE_TYPE_BPS = 'bps'
export const SERVICE_TYPE_ANALYSIS = 'analysis'
export const SERVICE_TYPE_PROGRAM = 'program'
export const SERVICE_TYPE_SUBSEQUENT = 'subsequent'
export const SERVICE_TYPE_SUBSEQUENT_BRIEF = 'subsequent_brief'
export const SERVICE_TYPE_SUBSEQUENT_COMPLEX = 'subsequent_visit_record_custom'
export const SERVICE_TYPE_DPP = 'dpp'
export const SERVICE_TYPE_HEALING = 'healing'
export const SERVICE_TYPE_HOME_PSYCHOLOGY = 'home_psychology'

// 服务类型 DPP循程治疗方案
export const SERVICE_TYPE = [
  {
    label: '量表',
    value: SERVICE_TYPE_SCALE
  },
  {
    label: 'BPS评估报告',
    value: SERVICE_TYPE_REPORT
  },
  {
    label: '新bps首诊报告',
    value: SERVICE_TYPE_NEW_REPORT
  },
  {
    label: '表单',
    value: SERVICE_TYPE_FORM
  },
  {
    label: '专科检测',
    value: SERVICE_TYPE_DETECTION
  },
  {
    label: 'BPS首咨记录',
    value: SERVICE_TYPE_BPS,
  },
  // {
  //   label: '需求分析',
  //   value: SERVICE_TYPE_ANALYSIS
  // },
  {
    label: '心理服务完整表',
    value: SERVICE_TYPE_SUBSEQUENT
  },
  {
    label: '心理服务记录',
    value: SERVICE_TYPE_SUBSEQUENT_BRIEF
  },
  // {
  //   label: '心理服务完整表',
  //   value: SERVICE_TYPE_SUBSEQUENT_COMPLEX
  // },
  // {
  //   label: 'DPP循程治疗方案',
  //   value: SERVICE_TYPE_DPP
  // },
  {
    label: '艺术疗愈记录',
    value: SERVICE_TYPE_HEALING
  },
  {
    label: '居家心理健康',
    value: SERVICE_TYPE_HOME_PSYCHOLOGY
  }
]

export const TASK_URL_MAP = {
  [SERVICE_TYPE_SCALE]: '/scale',
  [SERVICE_TYPE_REPORT]: '/evaluation',
  [SERVICE_TYPE_NEW_REPORT]: '/evaluation-new',
  [SERVICE_TYPE_DETECTION]: '/specialist-test',
  [SERVICE_TYPE_BPS]: '/bsp-visit',
  // [SERVICE_TYPE_ANALYSIS]: '/demand-analysis',
  [SERVICE_TYPE_ANALYSIS]: '',
  [SERVICE_TYPE_PROGRAM]: '/program',
  [SERVICE_TYPE_SUBSEQUENT]: '/subsequent',
  [SERVICE_TYPE_SUBSEQUENT_BRIEF]: '/subsequent-brief',
  // [SERVICE_TYPE_SUBSEQUENT_COMPLEX]: '/service-subsequent-complex',
  // [SERVICE_TYPE_DPP]: '/dpp-treatment',
  [SERVICE_TYPE_DPP]: '',
  [SERVICE_TYPE_HEALING]: '/healing',
  [SERVICE_TYPE_HOME_PSYCHOLOGY]: '/home-psychology',
}

// 产品类型-服务
export const PRODUCT_CATEGORY_SERVICE = 'service'
// 产品类型-药品
export const PRODUCT_CATEGORY_DRUG = 'drug'


export const DRUG = {
  unit: [
    {
      lable: '片',
      value: '片'
    },
    {
      lable: 'mg',
      value: 'mg'
    }
  ],
  frequency: [
    {
      lable: 'Qd',
      value: 'Qd'
    },
    {
      lable: 'Bid',
      value: 'Bid'
    },
    {
      lable: 'Tid',
      value: 'Tid'
    },
    {
      lable: 'Qid',
      value: 'Qid'
    }
  ],
  usage: [
    {
      lable: '口服用药',
      value: '口服用药'
    },
    {
      lable: '冲服',
      value: '冲服'
    },
    {
      lable: '冲洗',
      value: '冲洗'
    },
    {
      lable: '外敷',
      value: '外敷'
    },
    {
      lable: '注射',
      value: '注射'
    }
  ],
  treatmentDays: [
    {
      lable: '1天',
      value: 1
    },
    {
      lable: '7天',
      value: 7
    },
    {
      lable: '一个月',
      value: 30
    },
    {
      lable: '三个月',
      value: '90'
    },
  ]
}
// 模板权限
export const PACKAGE_TYPE = [
  {
    lable: '门店',
    value: 'clinic'
  },
  {
    lable: '个人',
    value: 'personal'
  },
]
// 客户类型
export const PERSONAL_POTENTIAL_TYPE = 'potential'
export const PERSONAL_FORMAL_TYPE = 'formal'

export const PERSONAL_TYPE = [
  {
    lable: '潜在客户',
    value: PERSONAL_POTENTIAL_TYPE
  },
  {
    lable: '正式客户',
    value: PERSONAL_FORMAL_TYPE
  }
]

// physiological
export const DPP_TREATMENT_PATH_PHYSIOLOGICAL = [
  {
    value: 'short',
    label: '短期(0-3个月)',
    treatment: '私人心理教练包月服务3个月；泛乐莱3个月（舒忧菌3盒+赛迪普兰9盒）；',
    treatmentGoals: [
      '1.问题梳理：抑郁焦虑情绪、睡眠等表现概念化和明确问题归类；',
      '2.方案定制：私人定制食品+心理+物理的个性化方案；',
      '3.私人服务：调整优化食品物理方案和频率；匹配合适的心理师，心理师1V1教授情绪、睡眠调整的技能。',
      '4.初步好转：症状痛苦缓解，恢复一定的社会功能。'
    ]
  },
  {
    value: 'amidst',
    label: '中期(3-9个月)',
    treatment: '泛乐莱6个月+私人心理教练按次服务6个月',
    treatmentGoals: [
      '1.阶段性评估：定期阶段性评估、持续优化方案；',
      '2.深入服务：症状痛苦基本消失，持续干预，巩固效果；',
      '3.学习技巧：心理师1V1教授情绪、睡眠调整的技能，并持续强化练习实践巩固；',
      '4.恢复和维持良好生活工作状态。'
    ]
  },
  {
    value: 'long',
    label: '长期(9-18个月)',
    treatment: '泛乐莱9个月+私人心理教练按次服务9个月',
    treatmentGoals: [
      '1.阶段性评估：定期阶段性评估、持续优化方案；',
      '2.巩固：保持低频干预，巩固效果；',
      '3.实践检验：通过工作生活的应激事件处理情况，检验技能掌握情况，私人心理教练针对弱项巩固练习，保证良好生活工作状态得以持续。'
    ]
  },
]

// psychological
export const DPP_TREATMENT_PATH_PSYCHOLOGICAL = [
  {
    value: 'short',
    label: '短期(0-3个月)',
    treatment: '个体成长+原生家庭梳理心理咨询12次',
    treatmentGoals: [
      '1.梳理童年经历，寻找潜意识中的被压抑的愿望',
      '2.缓解童年创伤带来的困扰'
    ]
  },
  {
    value: 'amidst',
    label: '中期(3-9个月)',
    treatment: '个体成长+原生家庭梳理心理咨询24次',
    treatmentGoals: [
      '1.梳理童年经历，寻找潜意识中的被压抑的愿望；',
      '2.梳理自我的冲突，使得压抑的潜意识进入意识，梳理防御机制，处理移情与反移情；',
      '3.梳理心理图式，寻找人格适应的突破口；',
      '4.基本解决困扰问题，提升应对能力和技巧。'
    ]
  },
  {
    value: 'long',
    label: '长期(9-18个月)',
    treatment: '个体成长+原生家庭梳理心理咨询36次',
    treatmentGoals: [
      '1.梳理童年经历，寻找潜意识中的被压抑的愿望；',
      '2.梳理自我的冲突，使得压抑的潜意识进入意识，梳理防御机制，处理移情与反移情；',
      '3.梳理依恋模式，探索亲密关系的困扰，总结归纳心理图式，获得应对情境的动力，提升人格适应能力；',
      '4.解决困扰问题，对触发机制进行反复探索认识，自我提升、促进身心健康。'
    ]
  },
]

// society
export const DPP_TREATMENT_PATH_SOCIETY = [
  {
    value: 'short',
    label: '短期(0-3个月)',
    treatment: '个体心理咨询12次+家属健康教育指导12次；OR私人家庭心理师服务3个月；',
    treatmentGoals: [
      '1.问题梳理：通过BPS概念化，清晰自己冲突问题的外在表现、内在诱因；',
      '2.方案定制：明确自己问题解决优先级排序。症状痛苦、冲突事件应对策略、思维认知模式、性格特质等；',
      '3.私人服务：匹配合适的心理师，1V1教授现实中学业、人际、恋爱、职场、家庭等主要问题和冲突应对的策略，提升自己的情绪表达和人际互动方式。',
      '4.效果评估：定期阶段性评估、实现冲突的初步改善。'
    ]
  },
  {
    value: 'amidst',
    label: '中期(3-9个月)',
    treatment: '个体心理咨询24次；家属健康教育指导24次；OR私人家庭心理师服务6个月；',
    treatmentGoals: [
      '1.阶段性评估：定期阶段性评估、持续优化方案；',
      '2.掌握新的行为方式：通过心理师1V1指导，让自己习得并掌握新的，面对现实中主要冲突应对行为方式。',
      '3.提升认知模式：探索及改善认知和思维模式，从意识层面发展出，在家庭社会生活中更适合自己的思考处理方式。',
      '4.功能回复：可以维持良好的生活、学习、工作、社交、娱乐状态。'
    ]
  },
  {
    value: 'long',
    label: '长期(9-18个月)',
    treatment: '个体心理咨询24次；家属健康教育指导24次；OR私人家庭心理师年度服务；',
    treatmentGoals: [
      '1.阶段性评估：定期阶段性评估、持续优化方案；',
      '2.实践检验和巩固：通过工作生活各种真实事件的应对表现，检验新的行为方式和认知思考模式掌握和应用的实际效果，针对弱项巩固练习，保证良好生活工作状态得以持续；',
      '3.自我提升：通过私人家庭心理教练的指导，持续学习技能，达到更好的生活学习工作状态，促进身心健康，提升幸福指数。'
    ]
  }
]

// subsequent possibilityOfShedding potentialCausesOfShedding
export const SUBSEQUENT_SELECTION_CONFIG = {
  POSSIBILITY_OFSHEDDING: [
    {
      lable: '有极大可能(大于70%)',
      value: '有极大可能(大于70%)'
    },
    {
      lable: '有较大可能(50%)',
      value: '有较大可能(50%)'
    },
    {
      lable: '有可能(30%)',
      value: '有可能(30%)'
    },
    {
      lable: '可能性较小(15%)',
      value: '可能性较小(15%)'
    },
    {
      lable: '可能性极小(10%以下)',
      value: '可能性极小(10%以下)'
    },
  ],
  POTENTIAL_CAUSES_OFSHEDDING: [
    {
      lable: '来访者对咨询关系不满意',
      value: '来访者对咨询关系不满意'
    },
    {
      lable: '咨询效果未达到来访者或家属预期',
      value: '咨询效果未达到来访者或家属预期'
    },
    {
      lable: '费用问题',
      value: '费用问题'
    },
    {
      lable: '来访者时间冲突',
      value: '来访者时间冲突'
    },
    {
      lable: '目前情况不适合咨询，需转介',
      value: '目前情况不适合咨询，需转介'
    },
    {
      lable: '来访者个人问题',
      value: '来访者个人问题'
    },
    {
      lable: '其他问题',
      value: '其他问题'
    },
  ]
}

// 客户诊疗档案表单类型枚举customer
export const CUSTOMER_SERVICE_TYPE = [
  {
    label: '量表',
    value: 'scale_log'
  },
  {
    label: '评估报告',
    value: 'bps_report'
  },
  // {
  //   label: '首诊BPS',
  //   value: 'first_visit_bps_record_sheet'
  // },
  {
    label: '需求分析',
    value: 'requirement_analysis'
  },
  {
    label: '检测测评',
    value: 'brain_health_detection'
  },
  {
    label: '绘画影射',
    value: 'drawing_reference'
  },
  {
    label: '居家心理训练',
    value: 'mental_training_record'
  },
  {
    label: '心理服务完整表',
    value: 'subsequent_visit_record'
  },
  {
    label: '沙盘影射',
    value: 'sand_table_reference'
  },
  {
    label: '艺术疗愈',
    value: 'art_healing_record'
  },
]

// 退款状态
export const REFUNT_STATUS = [{
  label: '待审核',
  value: 'to_audit'
}, {
  label: '审核通过',
  value: 'success'
}, {
  label: '审核不通过',
  value: 'fail'
}]


// export const ORDER_EXECUTION_STATUS_MAP = {
//   [ORDER_EXECUTION_STATUS_TO_RESERVATION]: '待预约',
//   [ORDER_EXECUTION_STATUS_EXECUTION]: '执行中',
//   [ORDER_EXECUTION_STATUS_PART_SUCCESS]: '部分完成',
//   [ORDER_EXECUTION_STATUS_SUCCESS]: '已完成'
// }

export const ORDER_REFUNT_STATUS_PART_REFUND = 'part_refund'
export const ORDER_REFUNT_STATUS_FULL_REFUND = 'full_refund'
export const ORDER_REFUNT_STATUS_REFUND = 'refund'
// 订单退款状态 
export const ORDER_REFUNT_STATUS_MAP = {
  // [ORDER_REFUNT_STATUS_PART_REFUND]: '部分退款',
  // [ORDER_REFUNT_STATUS_FULL_REFUND]: '全部退款',
  [ORDER_REFUNT_STATUS_REFUND]: '已退款',
}

export const ORDER_REFUNT_STATUS = [{
  label: '已退款',
  value: ORDER_REFUNT_STATUS_REFUND
}]

// 订单类型 public enum OrderTypeEnum {
export const ORDER_TYPE_ENUM = [{
  label: '新签',
  value: 'first'
}, {
  label: '续费',
  value: 'renew'
}]
export const ORDER_TYPE_ENUM1 = [{
  label: '首单',
  value: 'first'
}, {
  label: '复购',
  value: 'renew'
}]

// 规则产品类型枚举：RULES_PRODUCT_TYPE
export const RULES_PRODUCT_TYPE = [{
  label: '任一',
  value: 'any'
}, {
  label: '同一',
  value: 'the_same'
}]

// 规则产品类型枚举：RULES_PRODUCT_TYPE
export const RULES_PRODUCT_TYPE_OBJ = {
  any: '任一',
  the_same: '同一'
}

// 升单类型
export const UPGRADE_ORDER_TYPE = [{
  label: '首诊升单',
  value: 'first_visit_upgrade_order'
}, {
  label: '复诊升单',
  value: 'subsequent_visit_upgrade_order'
}]

export const UPGRADE_ORDER_TYPE_OBJ = {
  first_visit_upgrade_order: '首诊升单',
  subsequent_visit_upgrade_order: '复诊升单'
}
export const UPGRADE_ORDER_TYPE_OBJ_NEW = {
  first_visit_upgrade_order: '新签升单',
  subsequent_visit_upgrade_order: '续费升单'
}

export const ROLE = {
  unknown: '成单人'
}

//支付类型
export const PAYMENT_TYPE = {
  payment: '收款',
  refund: '退款'
}

//新老客户
export const CUSTOMER_TYPE = {
  new_customer: '新客户',
  old_customer: '老客户',
  none: ''
}

//新老客户
export const CUSTOMER_TYPE_ARRAY = [
  {
    text: '新客户',
    value: 'new_customer'
  },
  {
    text: '老客户',
    value: 'old_customer'
  }
]

export const APPLICATION_SYS = {
  '安肯云平台': 1,
  '安肯云诊所': 2,
  '互联网医院': 3,
  '安肯集团': 4,
}

export const APPLICATION_SYS_ARRAY = Object.keys(APPLICATION_SYS).map((key) => {
  return {
    label: key,
    value: APPLICATION_SYS[key]
  }
})

//到店状态（未到店／首次到店／复诊到店）
export const ARRIVAl_STATUS = {
  // not_arrival:'未到店',
  // first_arrival:'首次到店',
  // subsequent_arrival:'复诊到店'
  //修改后的
  not_arrive: '未到店',
  first_arrive: '首次到店',
  again_arrive: '复诊到店'
}

export const ARRIVAl_STATUS_ARRAY = Object.keys(ARRIVAl_STATUS).map((key) => {
  return {
    label: ARRIVAl_STATUS[key],
    value: key
  }
})

export const ATTACH_CENTER = [
  { label: '儿童心理', value: 1 },
  { label: '青少年心理', value: 2 },
  { label: '人际职场+个人成长', value: 3 },
  { label: '恋爱婚姻', value: 4 },
  { label: '情绪睡眠+心理疾病', value: 5 },
  { label: '不确定', value: 6 }
]

//订单来源
export const ORDER_SOURCE = {
  platform: '集团下单',
  clinic: '门店下单',
  '诊所下单': 'clinic',
  '集团下单': 'platform'
}

export const ORDER_SOURCE_ARRAY = [
  { label: '集团下单', value: 'platform' },
  { label: '门店下单', value: 'clinic' },
]

//渠道分类属性
export const CHANNEL_TYPE = {
  level_1_channel: "一级渠道分类",
  level_2_channel: "二级渠道分类",
  '一级渠道分类': 'level_1_channel',
  '二级渠道分类': 'level_2_channel'
}

//排班类型
export const SCHEDULE_TYPE = [{
  label: '日常排班',
  value: 'daily_schedule'
}]

export const SCHEDULE_TYPE_OBJ = {
  daily_schedule: '日常排班',
  '日常排班': 'daily_schedule'
}

// "data": {
//   "formalCustomer": {  # 正式客户
//       "waitStart": 24,  # 待开始             
//       "normalCustomer": 99,  # 正常
//       "exceptionCustomer": 9, # 异常
//       "pauseCustomer": 54, # 暂停
//       "fallOffCustomer": 309, # 脱落
//       "partRefundCustomer": 133, # 退费
//       "normalFinishCustomer": 2137 # 已结束
//   },
//   "potentialCustomer": { # 潜在客户
//       "invalidCustomer": 28,   # 无效客户
//       "noReserveCustomer": 62, # 未预约
//       "onlyReserveCustomer": 500 # 仅预约
//   }
// }
// export const TABLE_LINK = {

// }

export const MONTH = [{ label: '1月', value: '01' }, { label: '2月', value: '02' }, { label: '3月', value: '03' }, { label: '4月', value: '04' }, { label: '5月', value: '05' }, { label: '6月', value: '06' }, { label: '7月', value: '07' }, { label: '8月', value: '08' }, { label: '9月', value: '09' }, { label: '10月', value: '10' }, { label: '11月', value: '11' }, { label: '12月', value: '12' }]
export const QUARTER = [{ label: '第一季度', value: '一' }, { label: '第二季度', value: '二' }, { label: '第三季度', value: '三' }, { label: '第四季度', value: '四' },]
export const YEAR = [{ label: '上半年', value: 'firstHalf' }, { label: '下半年', value: 'lowerHalf' }, { label: '全年', value: 'allYear' }]


//正则  数字和字母组合大于6
export const REG = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,}$/ 
